import * as Sentry from '@sentry/browser'

Sentry.init({
    // sentry-js-testing - https://05a0b591ebfd448e7085cf3b9bb62125@sentry.diera.ru/17
    // fast-service-ru - https://f67f854f879f1369ff9504288c21c584@sentry.diera.ru/29
    dsn: 'https://f67f854f879f1369ff9504288c21c584@sentry.diera.ru/29',
    release: 'fast-service-ru@static',
    integrations: [
        new Sentry.BrowserTracing(),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1
})